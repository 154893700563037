import React from "react";
import { Helmet } from "react-helmet";
import { BrowserRouter } from "react-router-dom";
import Routes from "./Routes";
import { AppRoot } from "./css/AppRoot";
import { Nav, Footer } from "./frame/";
import { SchemaContext } from "./components/Schema";

function App() {
  const skills =
    "TypeScript, JavaScript, HTML, CSS, React, Node.js, Koa, Express, Redux, S3, RDS/Aurora, Route 53, EC2, ELB, Lambda, Jest, Mocha, Chai, MySQL, PostgreSQL, GIT, JIRA, SVN, Confluence, Trello";
  const meta = {
    description:
      "Naimah Nash, polyglot, currently performing javascript/typescript development at Netflix",
    title: "Naimah Nash | Senior Software Engineer, Studio UI at Netflix",
    keywords:
      "naimah, nash, naimah nash, naimah mumin, polyglot, software engineer, engineer, programmer, developer," +
      skills,
  };
  return (
    <BrowserRouter>
      <AppRoot />
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=UA-123791869-1"
        />
        {process.env.NODE_ENV === "production" && (
          <script>
            {`window.dataLayer = window.dataLayer || [];
          function gtag() {dataLayer.push(arguments); }
          gtag('js', new Date());
      
          gtag('config', 'UA-123791869-1');`}
          </script>
        )}

        <meta charSet="utf-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <title>{meta.title}</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="description" content={meta.description} />
        <meta name="keywords" content={meta.keywords} />
        <link rel="canonical" href="http://naimahnash.com/" />
        <meta property="og:url" content="http://naimahnash.com/" />
        <meta property="og:title" content={meta.title} />
        <meta property="og:description" content={meta.description} />
        <meta name="og:image" content="http://naimahnash.com/nai_laugh.jpg" />
        <meta name="og:image:type" content="image/jpg" />
        <meta name="og:image:width" content="1200" />
        <meta name="og:image:height" content="627" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content="@nashnaimahnash" />
        <meta name="twitter:title" content={meta.title} />
        <meta name="twitter:description" content={meta.description} />
        <meta
          name="twitter:image"
          content="http://naimahnash.com/nai_laugh_l.jpg"
        />
        <meta name="author" content="Naimah Nash" />
        <link rel="icon" type="image/jpg" href="/nai_laugh.jpg" />
        <link
          href="https://fonts.googleapis.com/css?family=PT+Sans|Work+Sans"
          rel="stylesheet"
        />
        <SchemaContext.Consumer>
          {({ schema }) => (
            <script type="application/ld+json">{JSON.stringify(schema)}</script>
          )}
        </SchemaContext.Consumer>
        <script type="application/ld+json">
          {`{
            "@context": "http://schema.org/",
            "@type": "Person",
            "givenName": "Naimah",
            "familyName": "Nash",
            "jobTitle": {
                "@type": "Text",
                "name": "Senior Software Engineer, Studio UI at Netflix"
            },
            "knowsLanguage": [
            ${skills
              .split(", ")
              .map(
                (skill, i) =>
                  `{
                  "@type": "Text",
                  "name": "${skill}"
                }`
              )
              .join(",")}],
            "hasCredential": {
              "@type": [
                "EducationalOccupationalCredential"
              ],
              "name": "Bachelor of Science, Computer Science"
            },
            "hasOccupation": {
              "@type": "Occupation",
              "name": "Senior Software Engineer, Studio UI at Netflix",
              "mainEntityOfPage": {
                "@type": "WebPage",
                "lastReviewed": "2020-02-29T14:20:00-05:00"
              },
              "description": "${meta.description}",
              "estimatedSalary": [
                {
                  "@type": "MonetaryAmountDistribution",
                  "name": "base",
                  "currency": "USD",
                  "unitText": "YEAR",
                  "minValue": "180000",
                  "maxValue": "250000"
                }
              ],
              "occupationLocation": [
                {
                  "@type": "City",
                  "name": "New York"
                }
              ],
              "qualifications": "Over 6 years of both personal and professional software engineering experience",
              "skills": "${skills}"
            }
            }`}
        </script>
      </Helmet>
      <div className="App">
        <Nav />
        <div id="body">
          <Routes />
        </div>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
