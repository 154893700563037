import { createGlobalStyle, css } from 'styled-components';
const Reset = css`
  html,
  body,
  div,
  span,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  tt,
  var,
  b,
  u,
  i,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  embed,
  figure,
  figcaption,
  footer,
  header,
  hgroup,
  nav,
  output,
  ruby,
  section,
  summary,
  time,
  mark,
  audio,
  video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 62.5%;
    vertical-align: baseline;
    box-sizing: border-box;
    font-weight: initial;
    max-width: 100%;
  }
  html {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 16px;
    vertical-align: baseline;
    box-sizing: border-box;
    font-weight: initial;
    max-width: 100%;
  }
  /* HTML5 display-role reset for older browsers */
  article,
  aside,
  canvas,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  nav,
  section {
    display: block;
  }
  html,
  body {
    height: 100%;
    -webkit-overflow-scrolling: touch;
  }
  ol,
  ul {
    list-style: none;
    list-style-type: none;
    padding-inline-start: 0;
  }
  blockquote,
  q {
    quotes: none;
  }
  blockquote:before,
  blockquote:after,
  q:before,
  q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  button,
  input,
  select,
  textarea {
    font-family: inherit;
    font-size: 100%;
    box-sizing: border-box;
  }
  input:-webkit-autofill,
  textarea:-webkit-autofill {
    box-shadow: 0 0 0 100px #f4f3ec inset; /* kill yellow Chrome autofill highlight */
  }
  select:disabled,
  option:disabled {
    -webkit-appearance: none;
    -moz-appearance: none;
    background: none;
  }
  /* select, option { 
     -webkit-appearance: none; 
     -moz-appearance: none; 
     background: none; 
   } */
  select:-moz-focusring {
    /* kill ugly dashed line around focused selects in Firefox */
    color: transparent;
    text-shadow: 0 0 0 #000;
  }
  hr {
    border: none;
    height: 1px;
    background-color: #dadada;
    color: #dadada;
  }
`;

const Typography = css`
  html,
  body {
    font-family: 'Open Sans';
    letter-spacing: 0;
    line-height: 29px;
    text-rendering: geometricprecision;
    -webkit-font-smoothing: antialiased;
  }

  a{
    text-decoration: none;
    color: #3399cc;
    &:visited{
      color: #3399cc;
    }
    a.active{
      font-weight: bold;
      color: #3399cc;
    }
  }

  a,
  p,
  div,
  span,
  li,
  button,
  input,
  select,
  textarea,
  article,
  td,
  th,
  em,
  strong{
    font-size: 18px;
  }

  strong{
    font-weight: bold;
    em{
      font-style: italic;
    }
  
  }

  em{
    font-style: italic;
    strong{
      font-weight: bold;
    }
  }

  p{
    a, span, li, strong, em{
      font-size: inherit;
    }
  }

  h1{
    font-size: 50px;
    line-height: 1em;
    margin-bottom: 15px;
    a, span, li, strong, em{
      font-size: inherit;
    }
  }

  h2{
    font-size: 42px;
    margin-bottom: 10px;
    a, span, li, strong, em{
      font-size: inherit;
    }
  }

  h3{
    font-size: 32px;
    a, span, li, strong, em{
      font-size: inherit;
    }
  }

  h4{
    font-size: 20px;
    a, span, li, strong, em{
      font-size: inherit;
    }
  }

  h5, label{
    font-size: 18px;
    text-transform: uppercase;
    font-weight: bold;
    a, span, li, strong, em{
      font-size: inherit;
    }
  }
`

const TypographyMobile = css`
@media only screen and (max-width: 480px){
  a,
  p,
  div,
  span,
  li,
  button,
  td,
  th,
  em,
  strong{
    font-size: 16px;
  }

  h1{
    font-size: 42px;
  }

  h2{
    font-size: 36px;
  }

  h3{
    font-size: 28px;
  }

  h4{
    font-size: 18px;
  }

  h5, label{
    font-size: 16px;
  }
}
`

export const AppRoot = createGlobalStyle`
  ${Reset}
  ${Typography}
  ${TypographyMobile}

  body,
  .App {
    margin: 0;
    height: 100vh;
    width: 100%;
  }
  .App {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  #body{
    padding-top: 80px;
    margin-top: 60px;
    margin-bottom: 110px;
  }
  @media screen and (max-width: 480px){
    #body{
      margin-top: 100px;
    }
  }
`;