import React from 'react'
import styled from 'styled-components'

const PageHeader: React.FC = ({ children }) => (
  <StyledPageHeader>
    {children}
  </StyledPageHeader>
)

export default PageHeader;

export const StyledPageHeader = styled.div`
  text-align: center;
  margin-bottom: 30px;
`