import React from 'react'
import { Switch, Route } from 'react-router-dom';
import ContentContainer from './components/ContentContainer'

// import Home from './pages/Home'
import Resume from './pages/Resume'
// import Projects from './pages/Projects'
// import Recipes from './pages/Recipes'
// // import LocationHistory from './pages/LocationHistory'
// // import TierTwo from './pages/TierTwo'

// export const routes = [
//   { path: "/", component: Home, title: "Naimah Nash", exact: true },
//   { path: "/about/", title :"About", component: Resume, exact: false },
//   { path: "/projects/", title :"Projects", component: Projects, exact: false },
//   { path: "/recipes/:recipe", title :"Recipes", component: Recipes, exact: true },
//   { path: "/recipes", component: Recipes, exact: true },
//   // { path: "/location/", component: LocationHistory, exact: true },
//   // { path: "/tier-two/", component: TierTwo, exact: true },
// ]

export const routes = [
    { path: "/", component: Resume, title: "Naimah Nash", exact: false },
]

const Routes: React.FC = () => (
  <Switch>
    <ContentContainer>
      {
        routes.map((route, i: number) => <Route key={i} {...route} />)
      }
    </ContentContainer>
  </Switch>
)

export default Routes;