import React from "react";
import styled from "styled-components";
import { Form as FormikForm } from "formik";

interface IButton {
  className?: string;
  onClick?: (props?: any) => void;
  text?: string;
  submitting?: boolean;
  pending?: boolean;
  type?: any;
}

export const Button: React.FC<IButton> = ({
  className = "",
  onClick,
  text,
  submitting,
  type = "button",
}) => (
  <StyledButton
    disabled={submitting}
    className={className}
    onClick={onClick}
    type={type}
  >
    {text}
  </StyledButton>
);

interface IRadioButton {
  checked: boolean;
  value: string;
  name?: string;
  onChange: (evt: any) => void;
}

export const RadioButton: React.FC<IRadioButton> = (props) => (
  <StyledRadioButton
    className={`radioButton ${props.checked ? "selected" : ""}`}
  >
    {props.value}
    <input type="radio" {...props} />
  </StyledRadioButton>
);

interface ICheckbox {
  checked: boolean;
  value: string;
  name?: string;
  label?: string;
  onChange: (evt: any) => void;
}

export const Checkbox: React.FC<ICheckbox> = (props) => (
  <StyledCheckbox className={`Checkbox ${props.checked ? "selected" : ""}`}>
    <input type="checkbox" {...props} />
    {props.label}
  </StyledCheckbox>
);

export const InputGroup: React.FC = ({ children }) => (
  <StyledInputGroup className="inputGroup">{children}</StyledInputGroup>
);

interface IInput {
  value: string | number;
  name?: string;
  type: string;
  onChange: (evt: any) => void;
  placeholder?: string;
}
export const Input: React.FC<IInput> = (props) => <StyledInput {...props} />;

interface ISelect {
  value: string | number;
  name?: string;
  onChange: (evt: any) => void;
}
export const Select: React.FC<ISelect> = ({ children, onChange, value }) => (
  <StyledSelect onChange={onChange} value={value}>
    {children}
  </StyledSelect>
);

const Form: React.FC = ({ children }) => <FormikForm>{children}</FormikForm>;

export default Form;

const StyledButton = styled.button`
  min-width: max-content;
  width: 120px;
  padding: 5px 10px;
  background: transparent;
  outline: none;
  margin-top: 5px;

  &:hover {
    background: #eee;
    cursor: pointer;
  }

  & + button {
    margin-left: 5px;
  }
`;
const StyledSelect = styled.select`
  min-width: max-content;
  width: 80px;
  padding: 5px 10px;
  background: transparent;
  outline: none;
  margin-top: 5px;

  &:hover {
    cursor: pointer;
  }
`;
const StyledInput = styled.input`
  min-width: max-content;
  width: 80px;
  padding: 5px 10px;
  background: transparent;
  outline: none;
  margin-top: 5px;

  &:hover {
    cursor: pointer;
  }
`;

const StyledRadioButton = styled.label`
  display: flex;
  flex-direction: row;
`;

const StyledCheckbox = styled.label`
  display: flex;
  flex-direction: row;
`;

const StyledInputGroup = styled.div`
  display: flex;
  flex-direction: column;
  .parallel {
    display: flex;
    flex-direction: row;
  }
`;
