import React, { useReducer } from "react";

interface IBasicSchema {
  "@type": string;
  name: string;
  [key: string]: any;
}
interface JobSchema extends IBasicSchema {
  jobTitle: IBasicSchema;
}
interface OccupationSchema extends IBasicSchema {
  "@type": "Occupation";
  name: "Senior Software Engineer, Studio UI at Netflix";
  mainEntityOfPage: {
    "@type": "WebPage";
    lastReviewed: Date;
  };
  description: string;
  estimatedSalary: [
    {
      "@type": "MonetaryAmountDistribution";
      name: "base";
      currency: "USD";
      unitText: "YEAR";
      minValue: number;
      maxValue: number;
    }
  ];
  occupationLocation: IBasicSchema[];
  qualifications: string;
  skills: string;
}
interface ISchema {
  "@context": "http://schema.org/";
  "@type": string;
  [key: string]: string | string[] | IBasicSchema[] | IBasicSchema;
}

const initialValues: (skills?: string, description?: string) => ISchema = (
  skills = "",
  description = ""
) => ({
  "@context": "http://schema.org/",
  "@type": "Person",
  givenName: "Naimah",
  familyName: "Nash",
  jobTitle: {
    "@type": "Text",
    name: "Senior Software Engineer, Studio UI at Netflix",
  },
  knowsLanguage: skills.split(", ").map((skill: string) => ({
    "@type": "Text",
    name: skill,
  })),
  hasCredential: {
    "@type": "EducationalOccupationalCredential",
    name: "Bachelor of Science, Computer Science",
  },
  hasOccupation: {
    "@type": "Occupation",
    name: "Senior Software Engineer, Studio UI at Netflix",
    mainEntityOfPage: {
      "@type": "WebPage",
      lastReviewed: "2020-02-29T14:20:00-05:00",
    },
    description,
    estimatedSalary: [
      {
        "@type": "MonetaryAmountDistribution",
        name: "base",
        currency: "USD",
        unitText: "YEAR",
        minValue: "180000",
        maxValue: "250000",
      },
    ],
    occupationLocation: [
      {
        "@type": "City",
        name: "New York",
      },
    ],
    qualifications:
      "Over 6 years of both personal and professional software engineering experience",
    skills,
  },
});
interface IAction {
  type?: "replace";
  state?: ISchema;
}
const schemaReducer = (state = initialValues(), action: IAction) => {
  switch (action.type) {
    case "replace":
      return action.state;
    default:
      return state;
  }
};

interface ISchemaContext {
  schema: ISchema;
  replaceSchema: (state: ISchema) => void;
}

export const SchemaContext = React.createContext<ISchemaContext>({
  schema: initialValues(),
  replaceSchema: () => {},
});

interface ISchemaWrapper {
  skills?: string;
  description?: string;
}

const Schema: React.FC<ISchemaWrapper> = ({ skills, description }) => {
  const [schema, dispatch] = useReducer<
    React.Reducer<ISchema, IAction>,
    ISchema
  >(schemaReducer, initialValues(skills, description), () =>
    initialValues(skills, description)
  );

  const replaceSchema = (state: ISchema) => {
    dispatch({ type: "replace", state });
  };

  console.log(
    "TCL ~ file: Schema.tsx ~ line 131 ~ JSON.stringify(schema)",
    JSON.stringify(schema)
  );
  return (
    <SchemaContext.Provider value={{ schema, replaceSchema }}>
      <script type="application/ld+json">{JSON.stringify(schema)}</script>
    </SchemaContext.Provider>
  );
};

export default Schema;
