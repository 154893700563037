interface IEvent {
  title: string;
  category: string;
  label?: string
}

export const pushEvent = (event: IEvent) => {
  (window as any)?.gtag?.('event', event.title, {
    'event_category': event.category,
    'event_label': event.label,
  });
}