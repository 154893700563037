// Nav.js
import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import ContentContainer from "../components/ContentContainer";
import {routes} from '../Routes'

const Nav: React.FC = () => (
  <StyledNav className="fullWidth">
    <ContentContainer>
      <h1>
        {routes
          .filter(({ path }) => path === "/")
          .map(({ path, title }) => (
            <NavLink to={path}>{title}</NavLink>
          ))}
      </h1>
      <nav>
        <ul>
          {routes
            .filter(({ path }) => path !== "/" && !path.includes('/:'))
            .map(({ path, title, exact }) => (
              <li>
                <NavLink exact={exact} activeClassName="active" to={path}>
                  {title}
                </NavLink>
              </li>
            ))}
        </ul>
      </nav>
    </ContentContainer>
  </StyledNav>
);

export default Nav;

const StyledNav = styled.header`
  padding-top: 20px;
  padding-bottom: 20px;
  background: white;
  position: fixed;
  border-bottom: 1px solid #ccc;
  width: 100%;
  z-index: 1000;
  a.active {
    font-weight: bold;
  }
  ul {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    li {
      font-size: 30px;
      margin-right: 10px;
      &:last-of-type {
        margin-right: 0;
      }
    }
  }
`;
