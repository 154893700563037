import React, { useState, useEffect } from "react";
import { pushEvent } from '../../utils/google'

interface ISkillList {
  label: string;
  list: any[];
  maxShow: number;
}

const SkillList: React.FC<ISkillList> = ({ label, list, maxShow = 4 }) => {
  const [listExpanded, expandList] = useState(false);
  const [listToDisplay, setList] = useState(list.length > maxShow ? [...list.slice(0, maxShow), 'toggle'] : list);

  useEffect(() => {
    if (listExpanded) {
      setList([...list.slice(0, maxShow), 'toggle', ...list.slice(maxShow)])
      if (window) {
        pushEvent({
          title: 'Expand skills',
          category: 'Code',
          label: label,
        });
      }
    } else {
      setList(list.length > maxShow ? [...list.slice(0, maxShow), 'toggle'] : list);
    }
  }, [listExpanded, label, list, maxShow])

  return (
    <>
      <label>{label}</label>
      <ul id={label.toLowerCase().replace(/\s/, '')}>
        {listToDisplay.map(skill =>
          skill === 'toggle' ?
            <li key="toggle" className={`expandIco ${listExpanded ? 'active' : ''}`} onClick={() => expandList(!listExpanded)}>[+]</li> :
            <li key={skill}>{skill}</li>
        )}
      </ul>
    </>
  );
}

export default SkillList;