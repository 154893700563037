import React, { useMemo } from "react";
import Meta from "../../components/Meta";
import styled from "styled-components";

import PageHeader, { StyledPageHeader } from "../../components/PageHeader";
import SectionHeader, {
  StyledSectionHeader,
} from "../../components/SectionHeader";
import SkillList from "./SkillList";
import skills from "./skills.json";
import experience from "./experience.json";
import { useRouteMatch } from "react-router";

interface IPosition {
  company: string;
  site: string;
  location: string;
  roles: {
    title: string;
    dates: string[];
    // dates: [string, string | null];
  }[];
  description: string[];
}
interface IExperience {
  position: IPosition;
}
const getEmploymentLength = (roles: IPosition["roles"]): [number, number] => {
  let start = roles[roles.length - 1].dates[0],
    end = roles[0].dates[1];
  let startDate = new Date(start.replace(" ", " 1, ")),
    endDate = end ? new Date(end.replace(" ", " 1, ")) : new Date();

  let duration: [number, number] = [
    endDate.getFullYear() - startDate.getFullYear(),
    endDate.getMonth() - startDate.getMonth(),
  ];
  if (duration[1] < 0) {
    duration[0]--;
    duration[1] += 12;
    if (duration[0] < 0) {
      duration[0] = 0;
      duration[1] = 0;
    }
  }
  return duration;
};

const formatDuration = (duration: [number, number]) => {
  let text = "",
    [years, months] = duration;
  if (years) {
    text += years + (years > 1 ? " years " : " year ");
  }
  if (months) {
    text += months + (months > 1 ? " months " : " month ");
  }
  return text.trim();
};
const Experience: React.FC<IExperience> = ({ position }) => {
  const { company, site, location, roles, description } = position;

  return (
    <div className="company">
      <h3 className="companyHeader">
        {site ? (
          <a href={site} target="_blank" rel="noopener noreferrer">
            {company}
          </a>
        ) : (
          <>{company}</>
        )}
        - {location}{" "}
        <span className="experience">
          {formatDuration(getEmploymentLength(roles))}
        </span>
      </h3>
      {roles.map(({ title, dates }) => (
        <h4 className="positionHeader">
          {title} —
          <em>
            {dates[0]} - {dates[1] || "PRESENT"}
          </em>
        </h4>
      ))}
      <div>
        {description.map((paragraph) => (
          <p>{paragraph}</p>
        ))}
      </div>
    </div>
  );
};


const Resume: React.FC = () => {
  const match  = useRouteMatch('/');
  const intro = useMemo(() => {
    if (match && match.isExact) {
      return (
        <PageHeader>
          <img src="/nai_laugh.jpg" alt="Naimah laughing" />
          <h1>Naimah Nash</h1>
          <h2>
            Senior Software Engineer
          </h2>
          <h3>Studio UI at Netflix</h3>
        </PageHeader>
      );
    }
    return (
      <>
        <PageHeader>
          <h1>About Naimah</h1>
        </PageHeader>
        <p>
          Hailing from the greatest city in the world, Pasadena, CA, Naimah is a
          full stack engineer with over a decade of both personal and
          professional software engineering experience.
        </p>
        <p>
          Naimah moved to San Francisco upon graduating from Pasadena High in
          2008, pursuing her undergraduate degree at San Francisco State
          University and graduating with a Bachelor's Degree in Computer Science
          in 2014.
        </p>
        <p>
          Upon graduation, Naimah realized her dream of moving to New York City.
          After nearly five years at Book of the Month, leaving as Director of
          Engineering, she has moved back to Los Angeles to work at Netflix.
        </p>
      </>
    );
  }, [match])
  return (
    <StyledResume>
      <Meta title="Naimah Nash's Resume | Studio UI at Netflix" />
      {intro}
      <section className="page fullWidth" style={{ textAlign: "left" }}>
        <main>
          <section id="experience">
            <SectionHeader>
              <h2>
                Experience{" "}
                <span className="experience">
                  {formatDuration(
                    getEmploymentLength(experience.flatMap((e) => e.roles))
                  )}
                </span>
              </h2>
            </SectionHeader>
            {experience.map((position: IPosition) => (
              <Experience position={position} />
            ))}
          </section>
          <section id="education">
            <SectionHeader>
              <h2>Education</h2>
            </SectionHeader>
            <div className="company">
              <h3 className="companyHeader">
                San Francisco State University - San Francisco
              </h3>
              <h4 className="positionHeader">
                Bachelor of Science in Computer Science —
                <em>August 2008 - December 2014</em>
              </h4>
              <div>
                <p>
                  Team lead in CSC 640: Software Engineering where the class was
                  divided into various “startups”. Member of the debate team and
                  women’s rugby.
                </p>
              </div>
            </div>
          </section>
        </main>
        <aside>
          <section id="skills">
            <SectionHeader>
              <h2>Skills</h2>
            </SectionHeader>
            {skills.map((skillList) => (
              <SkillList {...skillList} />
            ))}
          </section>
        </aside>
      </section>
    </StyledResume>
  );
};

export default Resume;

const StyledResume = styled.div`
  font-family: "Work Sans", sans-serif;
  & > section {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }

  p {
    line-height: 1.5em;
    margin-bottom: 1em;
    margin-top: 1em;
  }

  .company > * {
    margin-left: 15px;
  }

  .company > .companyHeader {
    margin-left: 0;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .experience {
    font-size: 0.5em;
  }

  .positionHeader {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  aside {
    margin-left: 10px;
    text-align: center;
  }

  li.expandIco:hover,
  li.expandIco.active {
    cursor: pointer;
    font-size: 1.2em;
    color: #3399cc;
  }

  .resume label {
    font-weight: bolder;
    border-bottom: 1px dashed #c9c9c9;
    padding-bottom: 0.25em;
  }

  ${StyledPageHeader} {
    img {
      border: 2px solid white;
      border-radius: 50%;
      max-width: 100%;
      width: 300px;
    }
  }

  #experience, #education {
    ${StyledSectionHeader} {
      margin-right: 20%;      
    }
  }

  @media only screen and (max-width: 480px) {
    & > section {
      flex-direction: column;
    }
    .companyHeader {
      display: flex;
      flex-direction: column;
      &first-of-type {
        margin-top: 0;
      }
    }
  }
`;
