import React from 'react'
import { Helmet } from 'react-helmet';

interface IMeta {
  description?: string;
  title?: string;
  canonical?: string;
  keywords?: string;
  image?: string;
}
const Meta: React.FC<IMeta> = ({ description, title, canonical, keywords, image }) => {
  return (
    <Helmet>
      {title && [
        <title>{title}</title>,
        <meta property="og:title" content={title} />,
        <meta name="twitter:title" content={title} />,
      ]}
      {description && [
        <meta name="description" content={description} />,
        <meta property="og:description" content={description} />,
        <meta name="twitter:description" content={description} />,
      ]}
      {keywords && <meta name="keywords" content={keywords} />}
      {canonical && [
        <link rel="canonical" href={canonical} />,
        <meta property="og:url" content={canonical} />,
      ]}
      {image && [
        <meta name="og:image" content="http://naimahnash.com/nai_laugh.jpg" />,
        <meta name="og:image:type" content="image/jpg" />,
        <meta name="twitter:image" content="http://naimahnash.com/nai_laugh_l.jpg" />,
      ]}
    </Helmet>
  )
}

export default Meta