import React from 'react'
import styled from 'styled-components'

const SectionHeader: React.FC = ({ children }) => (
  <StyledSectionHeader>
    {children}
  </StyledSectionHeader>
)

export default SectionHeader;

export const StyledSectionHeader = styled.div`
  margin-top: 20px;
  margin-bottom: 30px;
  border-bottom: 2px solid #ccc;
  h2{
    margin-top: 20px;
    margin-bottom: 20px;
    & + p{
      margin-bottom: 20px;
    }
  }
`