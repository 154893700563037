// Footer.js
import React from 'react';
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import ContentContainer from '../components/ContentContainer'

const Footer: React.FC = () => (
  <StyledFooter className="fullWidth">
    <ContentContainer>
      <p>© Naimah Nash {new Date().getFullYear()}</p>
      <nav>
        <ul>
          <li><a href="https://www.linkedin.com/in/naimahnash" target="_blank" rel="noopener noreferrer" >
            <img src="/LinkedInLogo.png" alt="LinkedIn logo" />
          </a>
          </li>
          {/* <li><a href="https://github.com/naimahm" target="_blank" rel="noopener noreferrer" >
            <img src="/GitHubLogo.png" alt="GitHub logo" />
          </a>
          </li> */}
          <li><a href="mail:hello@naimahnash.com" target="_blank" rel="noopener noreferrer" >
            <img src="/gmail.png" alt="Gmail logo" />
          </a>
          </li>
          <li><NavLink exact activeClassName='active' to="/about/">About</NavLink></li>
          {/* <li><NavLink exact activeClassName='active' to="/projects/">Projects</NavLink></li> */}
        </ul>
      </nav>
    </ContentContainer>
  </StyledFooter>

)
export default Footer;

const StyledFooter = styled.footer`
  background: white;
  border-top: 1px solid #ccc;
  display: flex;
  align-items: center;
  bottom: 0;
  width: 100%;
  height: 80px;
  padding-top: 20px;
  padding-bottom: 20px;


  nav{
    display: flex;
    align-items: center;

    ul{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      li{
        font-size: 30px;
        margin-right: 10px;
        &:last-of-type{
          margin-right: 0;
        }
      }
    }
  }

  img{
    background: #000;
    color: #000;
  }
  a{
    color: #000;
  }


`