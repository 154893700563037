import React from "react";
import styled from "styled-components";

const ContentContainer: React.FC = ({ children }) => (
  <StyledContentContainer>{children}</StyledContentContainer>
);

export default ContentContainer;

export const StyledContentContainer = styled.div`
  /* width: 1100px; */
  max-width: 1400px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10%;
  padding-right: 10%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 480px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;
